<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>{{ dataItem.title }}</b-card-title>
        <b-card-sub-title class="pt-1">
          {{ dataItem.subTitle }}
        </b-card-sub-title>
      </div>
      <div class="font-weight-bolder font-medium-3 text-primary">
        %{{ dataItem.percent_score }}
      </div>
    </b-card-header>
    <b-card-body>
      <template v-for="(item,key) in dataItem.items">
        <question-card
          :key="'question_' + groupKey + '_' + item.id"
          :data-item="item"
          :question-key="key"
          :group-key="groupKey"
        />
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import QuestionCard from '@/views/App/NewStartups/SDG/QuestionCard.vue'

export default {
  name: 'GroupCard',
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    QuestionCard,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    groupKey: {
      type: Number,
      required: true,
    },
  },
}
</script>
