<template>
  <div>
    <startup-card />
    <result-card
      v-if="sdgSaveStatus.status !== null"
      :result="sdgSaveStatus"
    />
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <template v-if="sdgQuestions.length">
        <template v-for="(item,key) in sdgQuestions">
          <group-card
            :key="'group_' + item.id"
            :data-item="item"
            :group-key="key"
          />
        </template>
        <div>
          <b-form-checkbox
            v-model="draftStatus"
            class="mb-1"
            switch
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-form-checkbox>
          <draft-save-alert v-if="draftStatus" />
        </div>
        <template v-if="draftStatus">
          <b-button
            variant="warning"
            @click="saveDraft"
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="primary"
            @click="saveData"
          >
            {{ $t('Gönder') }}
          </b-button>
        </template>
      </template>
    </validation-observer>
  </div>
</template>

<script>
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import StartupCard from '@/views/App/Interests/Requests/components/StartupCard.vue'
import GroupCard from '@/views/App/NewStartups/SDG/GroupCard.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ResultCard from '@/views/App/NewStartups/ResultCard.vue'
import DraftSaveAlert from '@/views/App/NewStartups/components/DraftSaveAlert.vue'

export default {
  name: 'Sdg',
  components: {
    DraftSaveAlert,
    ResultCard,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    StartupCard,
    GroupCard,
  },
  data() {
    return {
      required,
      draftStatus: false,
    }
  },
  computed: {
    sdgQuestions() {
      return this.$store.getters['sdg/sdgQuestions']
    },
    sdgSaveStatus() {
      return this.$store.getters['sdg/sdgSaveStatus']
    },
  },
  created() {
    this.getStartup()
    this.getSdgQuestions()
    localize(this.$i18n.locale)
  },
  methods: {
    getStartup() {
      this.$store.dispatch('memberStartups/memberStartupView', this.$route.params.id)
    },
    getSdgQuestions() {
      this.$store.dispatch('sdg/getSdgQuestions', {
        id_startups: this.$route.params.id,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('sdg/saveData', { data: this.sdgQuestions, id_startups: this.$route.params.id })
        } else {
          this.toastAlert({
            icon: 'InfoIcon',
            title: 'Uyarı!',
            text: 'Lütfen tüm soruları eksiksiz doldurduğunuzdan emin olun.!',
            variant: 'danger',
          })
        }
      })
    },
    saveDraft() {
      this.$store.dispatch('sdg/saveDraft', {
        data: this.sdgQuestions,
        id_startups: this.$route.params.id,
      })
    },
    toastAlert(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
