<template>
  <div class="mb-2">
    <hr v-if="questionKey > 0">
    <b-form-group :label="dataItem.title">
      <validation-provider
        #default="{ errors }"
        :name="dataItem.title"
        rules="required"
      >
        <b-form-radio-group
          v-model="sdgQuestions[groupKey].items[questionKey].score"
          :options="dataItem.options"
          text-field="title"
          value-field="score"
          @change="setGroupPercent"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'QuestionCard',
  components: {
    ValidationProvider,
    BFormRadioGroup,
    BFormGroup,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    groupKey: {
      type: Number,
      required: true,
    },
    questionKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    sdgQuestions() {
      return this.$store.getters['sdg/sdgQuestions']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  methods: {
    setGroupPercent() {
      const groupScore = []
      const { items } = this.sdgQuestions[this.groupKey]
      items.forEach(e => {
        groupScore.push(Number(e.score))
      })
      const totalScore = groupScore.reduce((total, number) => total + number, 0)
      const percent = (totalScore / 10) * 100
      this.sdgQuestions[this.groupKey].percent_score = percent
    },
  },
}
</script>
